import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "forum-name text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.item.url,
    class: "text-truncate",
    tabindex: "-1"
  }, [
    _createElementVNode("span", {
      innerHTML: _ctx.highlight(_ctx.item.title)
    }, null, 8 /* PROPS */, _hoisted_2),
    _cache[0] || (_cache[0] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
    _createElementVNode("small", _hoisted_3, " w " + _toDisplayString(_ctx.item.forum.name), 1 /* TEXT */)
  ], 8 /* PROPS */, _hoisted_1))
}