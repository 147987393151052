import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: $props.item.url,
    class: "text-truncate",
    tabindex: "-1"
  }, [
    _createElementVNode("span", {
      innerHTML: _ctx.highlight(_ctx.text)
    }, null, 8 /* PROPS */, _hoisted_2)
  ], 8 /* PROPS */, _hoisted_1))
}