import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "alert alert-danger alert-report" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_vue_modal = _resolveComponent("vue-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.modal.open())),
      type: "button",
      class: "close",
      "data-dismiss": "alert",
      "aria-label": "Close",
      title: "Usuń"
    }, [
      _createVNode(_component_vue_icon, { name: "reportClose" })
    ]),
    _createVNode(_component_vue_username, {
      user: $props.flag.user,
      class: "alert-link"
    }, null, 8 /* PROPS */, ["user"]),
    _cache[5] || (_cache[5] = _createTextVNode(" zgłosił ")),
    _createElementVNode("a", {
      href: $props.flag.url
    }, _toDisplayString($options.elementNameAccusative), 9 /* TEXT, PROPS */, _hoisted_2),
    _cache[6] || (_cache[6] = _createTextVNode(" z powodu ")),
    _createElementVNode("strong", null, _toDisplayString($props.flag.name), 1 /* TEXT */),
    _cache[7] || (_cache[7] = _createTextVNode(" dnia ")),
    _createVNode(_component_vue_timeago, {
      datetime: $props.flag.created_at
    }, null, 8 /* PROPS */, ["datetime"]),
    ($props.flag.text)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.flag.text), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_vue_modal, { ref: "modal" }, {
      title: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("Zamknięcie raportu")
      ])),
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.modal.close())),
          type: "button",
          class: "btn btn-secondary",
          "data-dismiss": "modal"
        }, "Anuluj"),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.closeFlag && $options.closeFlag(...args)), ["prevent"])),
          class: "btn btn-danger danger"
        }, "Tak, zamknij")
      ]),
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "Czy na pewno chcesz zamknąć ten raport?", -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}