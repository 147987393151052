
import {nextTick} from "../vue";

export default {
  name: 'VueModal',
  data() {
    return {
      isOpen: false,
      bodyOverflow: '',
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      nextTick(() => {
        this.$refs['container'].focus(); // firefox hack: set focus to make Esc button works
        this.bodyOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
      });
    },
    close() {
      this.isOpen = false;
      document.body.style.overflow = this.bodyOverflow;
    },
  },
};
