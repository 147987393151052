import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-1" }
const _hoisted_2 = { class: "row mt-2" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tags_inline = _resolveComponent("vue-tags-inline")!
  const _component_vue_markdown = _resolveComponent("vue-markdown")!
  const _component_vue_button = _resolveComponent("vue-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.saveMicroblog && $options.saveMicroblog(...args)), ["prevent"]))
  }, [
    _createVNode(_component_vue_markdown, {
      modelValue: _ctx.microblog.text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.microblog.text) = $event)),
      assets: _ctx.microblog.assets,
      emojis: $data.emojis,
      "auto-insert-assets": false,
      onSave: $options.saveMicroblog,
      onCancel: _ctx.cancel,
      ref: "markdown",
      "preview-url": "/Mikroblogi/Preview"
    }, {
      bottom: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_vue_tags_inline, {
            tags: _ctx.microblog.tags,
            placeholder: "...inny? kliknij, aby wybrać tag",
            onChange: $options.toggleTag,
            "popular-tags": $props.popularTags
          }, null, 8 /* PROPS */, ["tags", "onChange", "popular-tags"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "assets", "emojis", "onSave", "onCancel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vue_button, {
          disabled: _ctx.isProcessing,
          onClick: $options.saveMicroblog,
          title: "Kliknij, aby wysłać (Ctrl+Enter)",
          class: "btn btn-sm btn-primary float-end",
          tabindex: "2",
          type: "submit"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Zapisz ")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        (_ctx.microblog.id)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
              title: "Anuluj (Esc)",
              class: "btn btn-sm btn-cancel btn-danger float-end me-2",
              tabindex: "3"
            }, " Anuluj "))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 32 /* NEED_HYDRATION */))
}