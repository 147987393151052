import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'show': $data.isDropdownVisible}, "dropdown-menu"])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */)), [
    [_directive_click_away, $options.hideDropdown]
  ])
}