import { resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createBlock(_component_vue_easy_lightbox, {
    visible: $options.visible,
    imgs: $options.lightboxImages,
    index: $props.index!,
    "move-disabled": true,
    "rotate-disabled": true,
    "zoom-disabled": true,
    "pinch-disabled": true,
    "dblclick-disabled": true,
    "mask-closable": true,
    onHide: $options.close
  }, {
    toolbar: _withCtx(() => _cache[0] || (_cache[0] = [])),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "imgs", "index", "onHide"]))
}