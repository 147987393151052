import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "i-45 me-2 d-none d-sm-block flex-shrink-0"
}
const _hoisted_2 = { class: "media-body" }
const _hoisted_3 = { class: "float-end text-muted" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 3,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_avatar = _resolveComponent("vue-avatar")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _directive_profile = _resolveDirective("profile")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'sequential': $props.message.sequential, 'unread': ! $options.isRead}, "media"])
  }, [
    (!$props.message.sequential)
      ? _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_1, [
          _createVNode(_component_vue_avatar, {
            photo: $props.message.user.photo,
            name: $props.message.user.name,
            initials: $props.message.user.initials,
            class: "mw-100 img-thumbnail media-object"
          }, null, 8 /* PROPS */, ["photo", "name", "initials"])
        ])), [
          [_directive_profile, $props.message.user.id]
        ])
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (!$props.message.sequential)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("small", _hoisted_3, [
              _createVNode(_component_vue_timeago, {
                datetime: $props.message.created_at
              }, null, 8 /* PROPS */, ["datetime"])
            ]),
            _createElementVNode("h3", null, [
              ($props.clickableText)
                ? (_openBlock(), _createBlock(_component_vue_username, {
                    key: 0,
                    user: $props.message.user,
                    href: '/User/Pm/Show/' + $props.message.id
                  }, null, 8 /* PROPS */, ["user", "href"]))
                : (_openBlock(), _createBlock(_component_vue_username, {
                    key: 1,
                    user: $props.message.user
                  }, null, 8 /* PROPS */, ["user"]))
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.deleteMessage && $options.deleteMessage(...args))),
        class: "btn-delete float-end text-danger",
        href: "javascript:",
        title: "Usuń"
      }, [
        _createVNode(_component_vue_icon, { name: "privateMessageDelete" })
      ]),
      ($props.clickableText)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: '/User/Pm/Show/' + $props.message.id,
            class: "excerpt"
          }, _toDisplayString($props.message.excerpt ? $props.message.excerpt : '(kliknij, aby przeczytać)'), 9 /* TEXT, PROPS */, _hoisted_4))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "pm-text",
            innerHTML: $props.message.text
          }, null, 8 /* PROPS */, _hoisted_5)),
      ($props.last && $props.message.folder === $data.SENTBOX && $props.message.read_at)
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, [
            _createVNode(_component_vue_icon, { name: "privateMessageReadAt" }),
            _cache[1] || (_cache[1] = _createTextVNode(" Przeczytano, ")),
            _createVNode(_component_vue_timeago, {
              datetime: $props.message.read_at
            }, null, 8 /* PROPS */, ["datetime"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}