
import DecoratorMixin from '../mixin';

export default {
  mixins: [DecoratorMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
