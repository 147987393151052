import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!

  return ($options.shouldShow)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["btn btn-follow neon-secondary-button", {follows: $options.follows, 'neon-follows':$options.follows}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkAuth($options.toggleState)))
      }, [
        _createVNode(_component_vue_icon, { name: "userFollow" }),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString($options.follows ? 'Obserwujesz' : 'Obserwuj'), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}