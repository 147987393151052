import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["href", "onClick", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['nav', `nav-${this.type}`, 'neon-tabber'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "nav-item"
      }, [
        _createElementVNode("a", {
          href: typeof key === 'string' ? key : 'javascript:',
          class: _normalizeClass(["nav-link neon-tabber-tab", {'active neon-tabber-tab-active': key === $props.currentTab}]),
          onClick: ($event: any) => (_ctx.$emit('change', key)),
          textContent: _toDisplayString(item)
        }, null, 10 /* CLASS, PROPS */, _hoisted_1)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}