
export default {
  props: {
    items: {
      type: [Array, Object],
      default: [],
    },
    currentTab: null,
    type: {
      type: String,
      default: 'tabs',
      validator(value: string): boolean {
        return value === 'tabs' || value === 'pills';
      },
    },
  },
};
