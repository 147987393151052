
import {mapActions, mapGetters, mapState} from "vuex";

import declination from '../../libs/declination';
import {openFlagModal} from "../../plugins/flags";
import {VueTimeAgo} from '../../plugins/timeago.js';
import store from "../../store/index";
import VueAvatar from '../avatar.vue';
import VueFlag from '../flags/flag.vue';
import VueIcon from "../icon";
import {MicroblogMixin} from "../mixins/microblog";
import mixins from '../mixins/user.js';
import VueUserName from '../user-name.vue';
import VueCommentForm from "./comment-form.vue";

export default {
  name: 'comment',
  mixins: [mixins, MicroblogMixin],
  store,
  components: {
    VueIcon,
    'vue-avatar': VueAvatar,
    'vue-username': VueUserName,
    'vue-comment-form': VueCommentForm,
    'vue-flag': VueFlag,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('microblogs', ['vote', 'loadVoters']),
    flagComment(): void {
      openFlagModal(this.$props.comment.url, this.$props.comment.metadata);
    },
    reply() {
      this.$emit('reply', this.comment.user);
    },
    deleteItem() {
      this.delete('microblogs/deleteComment', this.comment);
    },
    restoreItem() {
      store.dispatch('microblogs/restoreComment', this.comment);
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAuthorized']),
    anchor() {
      return `comment-${this.comment.id}`;
    },
    highlight() {
      return '#' + this.anchor === window.location.hash;
    },
    commentLabel() {
      return this.comment.votes + ' ' + declination(this.comment.votes, ['głos', 'głosy', 'głosów']);
    },
    commentVoters() {
      return this.splice(this.comment.voters);
    },
    flags() {
      return store.getters['flags/filter'](this.comment.id, 'Coyote\\Microblog');
    },
  },
};
