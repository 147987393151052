
import VueIcon from "./icon";
import {default as mixin} from './mixins/form.js';

export default {
  name: 'VueProgressBar',
  emits: ['click'],
  mixins: [mixin],
  components: {'vue-icon': VueIcon},
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 3,
    },
    tooltips: {
      type: Array,
      default: () => ['podstawy', 'średnio zaawansowany', 'zaawansowany'],
    },
  },
  methods: {
    setValue(value) {
      if (!this.editable) {
        return;
      }
      this.$emit('click', value);
    },
  },
};
