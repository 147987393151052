import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modal-dialog" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = {
  key: 0,
  class: "modal-body"
}
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    tabindex: "-1",
    class: _normalizeClass(["modal fade background-darken", {'d-block': $data.isOpen, 'show': $data.isOpen}]),
    role: "dialog",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.close()), ["self"])),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => ($options.close()), ["esc"])),
    ref: "container"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", _hoisted_4, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _cache[3] || (_cache[3] = _createTextVNode(" Błąd "))
            ])
          ])
        ]),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "buttons", {}, () => [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-dismiss": "modal",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.close()))
            }, "OK")
          ])
        ])
      ])
    ])
  ], 34 /* CLASS, NEED_HYDRATION */)), [
    [_vShow, $data.isOpen]
  ])
}