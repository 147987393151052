import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  ref: "dropdown",
  class: "dropdown-alerts dropdown-menu dropdown-menu-end"
}
const _hoisted_2 = {
  key: 0,
  class: "text-center p-3"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center p-3 empty-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_message = _resolveComponent("vue-message")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("li", {
    class: _normalizeClass({'open': $data.isOpen})
  }, [
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.loadMessages && $options.loadMessages(...args))),
      class: "nav-link nav-control-icon neon-navbar-text"
    }, [
      _withDirectives(_createElementVNode("span", { class: "badge neon-notification-alert-count" }, _toDisplayString(_ctx.count), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, _ctx.count > 0]
      ]),
      _createVNode(_component_vue_icon, { name: "navigationPrivateMessages" })
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "dropdown-header" }, [
        _createElementVNode("a", {
          class: "float-end small",
          href: "/User/Pm/Submit"
        }, " Wyślij wiadomość "),
        _createElementVNode("a", {
          title: "Przejdź do listy wiadomości",
          href: "/User/Pm"
        }, " Wiadomości ")
      ], -1 /* HOISTED */)),
      _createVNode(_component_perfect_scrollbar, {
        class: "dropdown-modal",
        options: {wheelPropagation: false, useBothWheelAxes: false, suppressScrollX: true}
      }, {
        default: _withCtx(() => [
          (_ctx.messages === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_vue_icon, {
                  name: "privateMessagesLoading",
                  spin: ""
                })
              ]))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
            return (_openBlock(), _createBlock(_component_vue_message, {
              message: message,
              key: message.id
            }, null, 8 /* PROPS */, ["message"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (Array.isArray(_ctx.messages) && _ctx.messages.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Brak wiadomości prywatnych. "))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ], 512 /* NEED_PATCH */), [
      [_vShow, $data.isOpen]
    ])
  ], 2 /* CLASS */)), [
    [_directive_click_away, $options.hideDropdown]
  ])
}