
import {VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store/index";
import VueIcon from "../icon";
import VueModal from '../modal.vue';
import VueUserName from "../user-name.vue";

export default {
  name: 'flag',
  components: {
    VueIcon,
    'vue-username': VueUserName,
    'vue-modal': VueModal,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    flag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: null as unknown as VueModal,
    };
  },
  methods: {
    closeFlag() {
      this.modal.close();
      store.dispatch('flags/delete', this.flag);
    },
  },
  computed: {
    elementNameAccusative(): string {
      for (const resource of this.flag.resources) {
        if (resource.resource_type === 'Coyote\\Post\\Comment') {
          return 'komentarz';
        }
        if (resource.resource_type === 'Coyote\\Comment') {
          return 'komentarz';
        }
      }
      for (const resource of this.flag.resources) {
        if (resource.resource_type === 'Coyote\\Post') {
          return 'post';
        }
        if (resource.resource_type === 'Coyote\\Microblog') {
          return 'mikroblog';
        }
        if (resource.resource_type === 'Coyote\\Job') {
          return 'ofertę pracy';
        }
      }
      return '';
    },
  },
  mounted() {
    this.modal = this.$refs.modal;
  },
};
