import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "progress-bar-dotted" }
const _hoisted_2 = ["aria-label", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.max, (i) => {
      return (_openBlock(), _createElementBlock("span", {
        "aria-label": $props.tooltips[i - 1],
        class: _normalizeClass(["text-primary", {editable: $props.editable}]),
        onClick: ($event: any) => ($options.setValue(i)),
        "data-balloon-pos": "down"
      }, [
        (_ctx.valueLocal < i)
          ? (_openBlock(), _createBlock(_component_vue_icon, {
              key: 0,
              name: "tagRank"
            }))
          : (_openBlock(), _createBlock(_component_vue_icon, {
              key: 1,
              name: "tagRanked"
            }))
      ], 10 /* CLASS, PROPS */, _hoisted_2))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}